/************************************************************************** 
			Harmony UI Toolkit - Custom JS for Framework
This file is compiled and includes all of the dependency libraries before 
the code seen below. Add custom code to the file below.
**************************************************************************/

jQuery(document).ready(function() {
	$.fn.selectpicker.Constructor.BootstrapVersion = '5';
	PR.prettyPrint();
	$('.dli-data-table').DataTable({});
	// FUNCTION START:  Isotope Quicksearch Filtering
		// Run DataTable plugin
	// END //

	// FUNCTION START:  Isotope Quicksearch Filtering
		var qsRegex;
		// Sets up the element to filter & layout mode
		var $grid = $('.components').isotope({
			itemSelector: 'section',
			layoutMode: 'fitRows',
			filter: function() {
				return qsRegex ? $(this).text().match( qsRegex ) : true;
			}
		});
	
		// use value of search field to filter
		var $quicksearch = $('.quicksearch').keyup( debounce( function() {
			qsRegex = new RegExp( $quicksearch.val(), 'gi' );
			// Adds .filled class to quicksearch input when there is content
			// if ($('#navSearch').val().length !== 0) {
			// 	$(this).addClass('filled');
			// };
			$('.components').isotope();
		}, 200 ));
		
		// filter items on button click
		$('.filter-list-group').on( 'click', 'button', function() {
			var filterValue = $(this).attr('data-filter');
			$grid.isotope({ filter: filterValue });

			// Remove .active from all, and add to clicked item only
			$("li.component-item.active").removeClass("active");
			$(this).parent("li").addClass("active");
			
			// Toggle 'open' class if you've clicked a top level item
			if ($(this).parent("li").hasClass('has-sublist')) {
				$(this).parent("li").siblings().removeClass("open");
				$(this).parent("li").toggleClass("open");
			};
			// Remove 'open' class if you've clicked the 'all components' button.
			if ($(this).parent("li").hasClass('show-all')) {
				$("li.component-item.open").removeClass("open");
			};
			// Remove 'show' class if you've clicked other button with change theme open.
			if ($(this).not('.themeSwitchBtn')) {
				$("div#theme-swatches.show").removeClass("show");
			};
		});
		$('.filter-list-group').on( 'click', 'button#overviewBtn', function() {
			$("li.component-item.active").removeClass("active");
			$("li.component-item.open").removeClass("open");
			$('#overviewBtn').parent("li").addClass("active");
		});
		// debounce so filtering doesn't happen every millisecond
		function debounce( fn, threshold ) {
			var timeout;
			threshold = threshold || 100;
			return function debounced() {
				clearTimeout( timeout );
				var args = arguments;
				var _this = this;
				function delayed() {
				fn.apply( _this, args );
				}
				timeout = setTimeout( delayed, threshold );
			};
		}
	// END //
	
	// FUNCTION START:  Show Code Snippet Panel
		jQuery('div.panelContent div.buttonRow button.btn-link.showHTML').on('click', function(){
			jQuery(this).parent().siblings('figure.highlight').toggleClass('show');
			$('.components').isotope();
		});
	// END //

	// FUNCTION START:  Copy code to Clipboard Function
		$("figure .copy").click(function(){
			$.trim($(this).closest("figure").find("pre").text());
			document.execCommand("Copy");
		
			//remove tooltip after 3 sec
			setTimeout(function () {
				$('[data-toggle="tooltip"]').tooltip('hide');
			}, 3000)
			
		});
		var clipboard = new ClipboardJS('.copy', {
			target: function(trigger) {
				return trigger.previousElementSibling;
			}
		});
		clipboard.on('success', function(e) {
			console.log(e);
		});
		clipboard.on('error', function(e) {
			console.log(e);
		});
	// END //

	// FUNCTION START:  'Copied' confirmation tooltip
		$(function () {
			$('[data-toggle="tooltip"]').tooltip({
				trigger: "click",
				title: "Copied",
				template: '<div class="tooltip dliflc-tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
			});
		});
	// END //


	// FUNCTION START:  Get SCSS for that section
	$("button.dlCSS").click(function openSCSS(){
	// Because there is combined SCSS for standard non-customized form items 
	// (text input, search input, select, textarea, checkbox & radio)
	// grab their shared _form-inputs.scss file instead of individual scss files for these only
		if ($(this).closest('section').is("#id_form-layout, #id_text-input, #id_special-inputs, #id_select, #id_textarea, #id_check-radio-toggle")) {
			var pid = "_form-inputs.scss";
		}
		
	// If component is not in above form-items list, build URL to the custom scss stylesheet for that component
		else {
			var pid = $(this).closest('section').attr("id").substring(2).concat('.scss');
		}
		window.open('dliflc-theme/scss/components/'+ pid, '_blank', 'window settings');
		return false;
	});
	// END //


	// DEMO FUNCTION START: Popovers
	// $(function () {
	// 	$('[data-bs-toggle="popover"]').popover({
	// 		template: '<div class="popover fcn" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div><a class="popover-link" href="#">Learn More</a></div>'
	// 	})
	// });
	// END //

	
	// FUNCTION START:  Toggle Dark class on body for dark mode
	jQuery("div.dark-toggle .form-check-input, div.dark-toggle .dark-toggle-label").on('click', function(){
		$('#dark-toggle-Label-light, #dark-toggle-Label-dark, .dark-toggle-colorhex-light, .dark-toggle-colorhex-dark').toggleClass('hide');
		$("body").toggleClass('theme-dark');
	});
	
	jQuery(".btn-group > .btn").on('click', function(){
		$('.btn-group > a.btn, .btn-group > button.btn').removeClass('active');
		$(this).addClass('active');
	});
	// END //

	// FUNCTION START: Bootstrap Breakpoint Alias Classes added to Body for ease of troubleshooting.
		function resizeListener() {
			widthOutput = window.innerWidth;
			if (widthOutput < 576) {
				$('body').addClass("bs-XS").removeClass("bs-SM bs-MD bs-LG bs-XL bs-XXL");          
			}
			if (widthOutput >= 576 && widthOutput < 768) {
				$('body').addClass("bs-SM").removeClass("bs-XS bs-MD bs-LG bs-XL bs-XXL");          
			}
			if (widthOutput >= 768 && widthOutput < 992) {
				$('body').addClass("bs-MD").removeClass("bs-XS bs-SM bs-LG bs-XL bs-XXL");          
			}
			if (widthOutput >= 992 && widthOutput < 1200) {
				$('body').addClass("bs-LG").removeClass("bs-XS bs-SM bs-MD bs-XL bs-XXL");          
			}
			if (widthOutput >= 1200 && widthOutput < 1400) {
				$('body').addClass("bs-XL").removeClass("bs-XS bs-SM bs-MD bs-LG bs-XXL");          
			}
			if (widthOutput >= 1400) {
				$('body').addClass("bs-XXL").removeClass("bs-XS bs-SM bs-MD bs-LG bs-XL");          
			}
		}
		window.addEventListener("resize", resizeListener);
		resizeListener();
	// END //
	
	// FUNCTION START: Sidebar expand/collapse
		$('.sidebar-list-group').on('click', 'button', function() {
			if ($(this).parent("li").hasClass('has-sublist')) {
				$(this).parent("li").siblings().removeClass("active");
				$(this).parent("li").siblings().removeClass("open");
				$(this).parent("li").toggleClass("active");
				$(this).parent("li").toggleClass("open");
			};

		});
	// END //
});